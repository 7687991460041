.profile {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  .profileInfo {
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;

    * {
      margin: 0;
    }
  }
}
//.styledButton {
//  background-color: #008CBA;; /* Green */
//  border: none;
//  color: white;
//  padding: 15px 32px;
//  text-align: center;
//  text-decoration: none;
//  display: inline-block;
//  font-size: 16px;
//  margin: 10px;
//}
//.styledEditButton{
//  background-color: #008CBA;; /* Green */
//  border: none;
//  color: white;
//  padding: 7px 16px;
//  text-align: center;
//  text-decoration: none;
//  display: inline-block;
//  font-size: 16px;
//  margin-top: 10px !important;
//}