.canvas{
  border: 1px solid #3b5998;
  height: 100%;
  #canvas {
    touch-action: none;
    width: 100%;
    height: 100%;
    //margin: 0 2.5%;
    overflow: hidden;
  }
}