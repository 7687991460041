@import '../../styles/theme';

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .auth {
    border: 1px solid map-get($theme, secondary-color);
    box-shadow: 0 4px 6px 0 hsla(0, 0, 0, 0.2);
    padding: 10px;
  }
}