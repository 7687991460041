.GameLobby {

  margin: 0 auto;

  max-width: 350px;

  .playerList{
    P {
      margin: 5px 0;

      &.currentPlayer {
        font-weight: bold;
      }
    }

    padding-bottom: 5px;
    border-bottom: 1px solid black;
  }


  h3 {
    .code {
      padding: 3px;
      border: 1px solid gray;
    }
    margin-bottom: 0.5em;
  }

  h4 {
    margin: 0;
  }
}