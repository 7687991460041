.RoundScreen {
  height: 100%;

  display: flex;
  flex-direction: column;

  & > div {
    overflow: hidden;
    margin: 0;
  }


  .Team {
    padding: 5px 10px;
    min-height: 7.5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid black;

    h4 {
      text-align: left;
      margin: 0;
    }

    div {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow: scroll;
      justify-content: space-around;
      flex-grow: 1;
      padding: 0 10px;

      p {
        margin: 2px;
        font-size: 1em;
      }
    }
  }

  .currentDrawer {
    min-height: 5%;
    padding: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0 0;
    }
  }

  .canvasContainer {
    flex-grow: 1;
    //min-height: 87.5%;
    max-width: 450px;
    max-height: 750px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 10px;
  }

  .guesses {
    pointer-events: none;
  }

  .guess {
    position: absolute;
    opacity: 0.9;
    bottom: 10px;
    width: 100%;
    height: 50px;

    form {
      height: 100%;
      display: flex;
      flex-direction: row;
      padding: 5px;

      input {
        flex-grow: 1;
        text-align: center;
      }
    }
  }
}