.Home {
  text-align: center;
  padding: 0 10px;

  .banner {
    width: 3000px;
    overflow: hidden;
    height: 125px;
    margin-top: 25px;

    img {
      animation-name: banner;
      animation-duration: 35s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      height: 125px;
      position: relative;
    }
  }
}

@keyframes banner {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

//Link {
//  display: block;
//}

