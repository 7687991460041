.PlayHome {
   margin: 0 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   h3 {
      margin: 20px 0;
   }

   * {
      margin: 0;
   }

   & > * {
      width: 100%;
      max-width: 350px;
      margin: 5px 0;
   }

   div {
      display: flex;
      justify-content: space-between;

      input {
         flex-grow: 1;
         margin-right: 5px;
      }
   }

   input {
      text-align: center;
   }
}