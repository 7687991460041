.Play{
  text-align: center;
  height: 100%;

  .leaderBoard {
    margin: 0 auto;
  }
  .error-message{
    color: #D8000C;
    font-size: 15px;
    background: rgba(225, 225, 225, 1);
    animation: 0.3s ease-out 0s 1 singleBlink;
    padding: 5px;
  }

  .ErrorContainer {
    pointer-events: none;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    position: absolute;
  }

  .PlayContent {
    height: 100%;
  }

  @keyframes singleBlink {
    0% {
      background-color: rgba(225, 225, 225, 1);
    }
    50% {
      background-color: rgba(255, 125, 0, 1);
    }
    100% {
      background-color: rgba(225, 225, 225, 1);
    }
  }
}